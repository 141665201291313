import Highcharts, { ChartScrollablePlotAreaOptions, Point } from 'highcharts';
import { PureComponent, RefObject, createRef } from 'react';
import ReactDOMServer from 'react-dom/server';

import { DatasetDataObject } from 'actions/datasetActions';
import { ChartTooltip } from 'components/embed';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  AggedChartColumnInfo,
  GradientPointOptions,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { DatasetSchema } from 'types/datasets';
import { getColorColumn, isSelectedColorDateType } from 'utils/colorColUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { getHeatmapStopPoint } from 'utils/gradientUtils';
import { cloneDeep, groupBy, keyBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { replaceVariablesInString } from 'utils/variableUtils';

import { DrilldownChart } from './shared/drilldownChart';
import {
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  formatLegend,
  formatValue,
  getAxisNumericalValue,
  getColorColNames,
  getColorZones,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  shouldProcessColAsDate,
  xAxisFormat,
  yAxisFormat,
} from './utils';
import { getAggColMinMax, getStops } from './utils/heatMapUtils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  schema: DatasetSchema;
  selectedColorColName?: string;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  setChartMenu: (info: ChartMenuInfo | null) => void;
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
};

const opType = OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2;

type SeriesOptions = Highcharts.SeriesHeatmapOptions;

class HeatMap extends PureComponent<Props> {
  drilldownRef: RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.drilldownRef = createRef<HTMLDivElement>();
  }
  render() {
    const { generalOptions, instructions, loading, variables } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(instructions, opType);

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }
    return (
      <DrilldownChart
        chartOptions={this._spec()}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
        }
        dataPanelId={this.props.dataPanelTemplateId}
        drilldownEntryPoints={this.props.drilldownEntryPoints}
        drilldownRef={this.drilldownRef}
        instructions={instructions}
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      generalOptions,
      previewData,
      schema,
      instructions,
      backgroundColor,
      globalStyleConfig,
      setChartMenu,
      dataPanelTemplateId,
    } = this.props;
    if (!instructions?.aggColumns || schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();

    const { xCategories, yCategories } = this.getAxisCategories();
    const { aggColName } = getColorColNames(schema, opType);

    const { valueFormatId, decimalPlaces } = this.getValueFormat();

    const heatMapFormat = instructions.chartSpecificFormat?.heatMap;

    const data = this.transformData();

    const hasClickEvents =
      this.getUnderlyingDrilldownEnabled() || !!generalOptions?.customMenu?.enabled;

    const drilldownRef = this.drilldownRef;

    return {
      chart: {
        type: 'heatmap',
        plotBorderWidth: 1,
        backgroundColor,
        scrollablePlotArea: this.getScrollablePlotAreaInstructions(yCategories),
      },
      series: data,
      title: sharedTitleConfig,
      plotOptions: {
        heatmap: {
          dataLabels: {
            allowOverlap: true,
            enabled: heatMapFormat?.showCellLabels,
            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
            formatter: function () {
              const value = this.point.value ?? 0;
              if (heatMapFormat?.hideZeros && value === 0) return;
              return formatValue({
                value,
                decimalPlaces: heatMapFormat?.valueFormat?.showDecimals
                  ? heatMapFormat?.valueFormat?.decimalPlaces
                  : 0,
                formatId: heatMapFormat?.valueFormat?.numberFormat?.id,
                hasCommas: true,
              });
            },
          },
        },
        series: {
          animation: false,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;
                const options = e.point.options;
                if (
                  options.x === undefined ||
                  options.y === undefined ||
                  options.y === null ||
                  !drilldownRef?.current
                )
                  return;

                const scrollOffsetY =
                  drilldownRef?.current.querySelector('div.highcharts-scrolling')?.scrollTop ?? 0;

                setChartMenu({
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX,
                  chartY: e.chartY - scrollOffsetY,
                  category: xCategories[options.x],
                  subCategory: yCategories[options.y],
                });
              },
            },
          },
        },
      },
      yAxis: {
        endOnTick: false,
        categories: yCategories,
        ...yAxisFormat(globalStyleConfig, instructions?.yAxisFormats?.[0]),
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
        },
      },
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        categories: xCategories,
        labels: {
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
          rotation: instructions.xAxisFormat?.rotationAngle,
        },
        visible: !instructions?.xAxisFormat?.hideAxisLine,
      },
      colorAxis: {
        dataClasses: instructions.colorFormat?.useZones
          ? this.getDataClasses(aggColName)
          : undefined,
        stops: !instructions.colorFormat?.useZones ? this.getStops(aggColName) : undefined,
        startOnTick: false,
        endOnTick: false,
        marker: { color: globalStyleConfig.base.actionColor.default },
        labels: {
          formatter: function () {
            const value = (this.value as number) ?? 0;
            return formatValue({
              value,
              decimalPlaces,
              formatId: valueFormatId,
              hasCommas: true,
            });
          },
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
      },
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          const xName = getPointCategoryName(this.point, 'x');
          const yName = getPointCategoryName(this.point, 'y');

          return ReactDOMServer.renderToStaticMarkup(
            <ChartTooltip
              globalStyleConfig={globalStyleConfig}
              header={`${xName}, ${yName}`}
              points={[
                {
                  color: String(this.point.color),
                  name: this.series.name,
                  value: this.point.value || 0,
                  format: { decimalPlaces, formatId: valueFormatId },
                },
              ]}
            />,
          );
        },
      },
    };
  };

  getStops = (aggColName: string): [number, string][] => {
    const {
      instructions,
      globalStyleConfig,
      variables,
      datasetNamesToId,
      datasetData,
      previewData,
    } = this.props;

    const getFloat = (
      opts: GradientPointOptions | undefined,
      min: number,
      max: number,
      defaultFloat: number,
    ) =>
      getHeatmapStopPoint(opts, min, max, defaultFloat, variables, datasetNamesToId, datasetData);

    return getStops(
      instructions?.chartSpecificFormat?.heatMap,
      aggColName,
      globalStyleConfig,
      previewData,
      getFloat,
    );
  };

  getDataClasses = (aggColName: string) => {
    const { instructions, variables, datasetNamesToId, datasetData, previewData } = this.props;

    const colorZones = getColorZones(
      instructions?.colorFormat,
      variables,
      datasetNamesToId,
      datasetData,
    );
    if (!colorZones || colorZones.length <= 1) return [];

    const { min, max } = getAggColMinMax(aggColName, previewData);

    const dataClasses = [];
    let prevMin;
    for (let i = 0; i < colorZones.length; i++) {
      const isLastZone = i === colorZones.length - 1;
      const zone = colorZones[i];
      const from = i === 0 ? min : prevMin;
      const to = isLastZone ? max : zone.value;
      prevMin = to;

      dataClasses.push({
        from: from,
        to: to,
        color: zone.color,
        name: isLastZone ? `>= ${from}` : `< ${to}`,
      });
    }

    return dataClasses;
  };

  getUnderlyingDrilldownEnabled = () => {
    return !!this.props.generalOptions?.enableRawDataDrilldown;
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      valueFormatId:
        instructions?.yAxisFormats?.[0]?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;
    const categoryColIsDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const colorColIsDate = isSelectedColorDateType(instructions || {});

    if (!previewData || (!categoryColIsDate && !colorColIsDate) || !schema || schema.length === 0)
      return;

    const xAxisColName = schema[0].name;
    const colorColName = getColorColNames(schema, opType).colorColName;

    previewData.forEach((row) => {
      if (!instructions?.categoryColumn?.column.type) return;

      // If it's a number, it has already been converted to milliseconds
      if (categoryColIsDate && typeof row[xAxisColName] !== 'number')
        row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);

      if (colorColIsDate && typeof row[colorColName] !== 'number')
        row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
    });
  };

  getAxisCategories = () => {
    const { instructions, previewData, schema, selectedColorColName } = this.props;
    const { xAxisColName, colorColName } = getColorColNames(schema, opType);
    const colorColumn = getColorColumn(instructions, selectedColorColName);

    const originalXCategories = new Set<string>();
    const xCategories: string[] = [];
    const originalYCategories = new Set<string>();
    const yCategories: string[] = [];

    previewData.forEach((row) => {
      const originalCategory = String(row[xAxisColName]);
      if (originalXCategories.has(originalCategory)) return;
      originalXCategories.add(originalCategory);

      const formatted = formatLabel(
        row[xAxisColName],
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.categoryColumn?.bucketSize,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      );
      xCategories.push(formatted);
    });

    if (instructions?.chartSpecificFormat?.heatMap?.sortedXAxis) {
      instructions?.chartSpecificFormat?.heatMap?.sortedXAxis.forEach(
        (stage: string, i: number) => {
          const stageIndex = xCategories.findIndex((category) => category === stage);
          if (stageIndex !== -1) {
            const stageItem = xCategories.splice(stageIndex, 1);
            xCategories.splice(i, 0, stageItem[0]);
          }
        },
      );
    }

    const sortedArray = cloneDeep(previewData);
    if (sortedArray.length) {
      if (!isNaN(getAxisNumericalValue(sortedArray[0][colorColName]))) {
        sortedArray.sort(
          (a, b) => getAxisNumericalValue(a[colorColName]) - getAxisNumericalValue(b[colorColName]),
        );
      } else {
        sortedArray.sort((a, b) =>
          (a[colorColName] ?? '').toString().localeCompare((b[colorColName] ?? '').toString()),
        );
      }
    }

    sortedArray.forEach((row) => {
      const originalCategory = String(row[colorColName]);
      if (originalYCategories.has(originalCategory)) return;
      originalYCategories.add(originalCategory);

      const formatted = formatLabel(
        row[colorColName],
        colorColumn?.column.type,
        colorColumn?.bucket?.id,
      );
      yCategories.push(formatted);
    });

    if (
      instructions?.chartSpecificFormat?.heatMap?.sortedYAxis &&
      instructions?.chartSpecificFormat?.heatMap?.sortedYAxis.length > 0
    ) {
      instructions?.chartSpecificFormat?.heatMap?.sortedYAxis.forEach(
        (stage: string, i: number) => {
          const stageIndex = yCategories.findIndex((s) => s === stage);
          if (stageIndex !== -1) {
            const stageItem = yCategories.splice(stageIndex, 1);
            yCategories.splice(yCategories.length - i, 0, stageItem[0]);
          }
        },
      );
    }

    return {
      xCategories,
      yCategories,
      originalXCategories: Array.from(originalXCategories),
      originalYCategories: Array.from(originalYCategories),
    };
  };

  transformData = (): SeriesOptions[] => {
    const { instructions, schema } = this.props;

    if (!instructions?.aggColumns?.length || !schema?.length) return [];

    return this.transformColorData(schema, instructions.aggColumns[0]);
  };

  transformColorData = (schema: DatasetSchema, aggCol: AggedChartColumnInfo): SeriesOptions[] => {
    const {
      previewData,
      instructions,
      selectedColorColName,
      variables,
      datasetNamesToId,
      datasetData,
    } = this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema, opType);
    const { xCategories, yCategories } = this.getAxisCategories();
    const colorColumn = getColorColumn(instructions, selectedColorColName);

    const dataByXAxis = groupBy(previewData, (row) =>
      formatLabel(
        row[xAxisColName],
        instructions?.categoryColumn?.column.type,
        instructions?.categoryColumn?.bucket?.id,
        instructions?.categoryColumn?.bucketSize,
        instructions?.xAxisFormat?.dateFormat,
        instructions?.xAxisFormat?.stringFormat,
      ),
    );

    if (!xCategories || !yCategories) return [];
    const data: [number, number, number][] = [];

    xCategories.forEach((xCategory, x) => {
      const dataByYAxis = keyBy(dataByXAxis[xCategory], (row) =>
        formatLabel(row[colorColName], colorColumn?.column.type, colorColumn?.bucket?.id),
      );
      yCategories.forEach((yCategory, y) => {
        if (dataByYAxis[yCategory]) {
          const row = dataByYAxis[yCategory];
          const value = getAxisNumericalValue(row[aggColName]);

          if (isNaN(value)) return;
          data.push([x, y, value]);
        } else {
          if (!instructions?.chartSpecificFormat?.heatMap?.nullMissingValues) data.push([x, y, 0]);
        }
      });
    });

    const name = aggCol.column.friendly_name
      ? replaceVariablesInString(
          aggCol.column.friendly_name,
          variables,
          datasetNamesToId,
          datasetData,
        )
      : getColDisplayText(aggCol) || aggColName;

    return [
      { type: 'heatmap', data, name, nullColor: '#FFFFFF', borderWidth: 1, borderColor: '#cccccc' },
    ];
  };

  getScrollablePlotAreaInstructions = (
    categories: string[],
  ): ChartScrollablePlotAreaOptions | undefined => {
    const { instructions } = this.props;

    if (!instructions?.chartSpecificFormat?.heatMap?.enableVerticalScroll) return;

    const minHeight = instructions?.chartSpecificFormat?.heatMap?.verticalCellHeight || 32;

    return {
      minHeight: categories.length * minHeight,
      opacity: 1,
    };
  };
}

function getPointCategoryName(point: Point, dimension: string) {
  const series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x'] || 0];
}

export { HeatMap };
