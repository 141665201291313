import { TypeSummaryReducer } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { CellProps, TypeColumn, TypeFooterRow } from '@inovua/reactdatagrid-enterprise/types';
import cx from 'classnames';

import { defaultFormatCellData } from 'components/dataTable/utils';
import { sprinkles } from 'components/ds';
import {
  CellStyleOverrides,
  getCellStyles,
  renderCell,
  renderNumberCell,
} from 'components/ds/DataGrid/columnUtils';
import { getCellAlignment, getFlexAlignments } from 'components/ds/DataGrid/utils';
import { EmbedPivotTableRow } from 'components/embed/EmbedPivotTable/index';
import { BOOLEAN, DATE_TYPES, DECIMAL_TYPES, NUMBER_TYPES } from 'constants/dataConstants';
import { DateDisplayOptions, DisplayOptions, NumberDisplayOptions } from 'constants/types';
import { formatDateField } from 'pages/dashboardPage/charts/utils';
import { ColumnConfigs } from 'types/columnTypes';
import { MetricsByColumn } from 'types/dashboardTypes';
import { DatasetColumn, DatasetRow, DatasetSchema } from 'types/datasets';
import { isDataRequiredForTableColumnGradient } from 'utils/gradientUtils';
import { keyBy } from 'utils/standard';

import { ColumnHeader } from '../EmbedDataGrid/ColumnHeader';

import * as styles from './pivotUtils.css';

type GeneratePivotColumnParams = {
  columnConfigs: ColumnConfigs;
  schema: DatasetSchema | undefined;
  pivotColumns?: string[];
  groupByColumns: string[];
  summaryColumnName?: string; //undefined in RB case
  wrapHeaderText?: boolean;
};

export type PivotColumns = {
  columns: TypeColumn[];
  groupColumn: TypeColumn;
};

type RenderCellData = { leaf: boolean; array: PivotTableRow[]; fieldPath: string[]; depth: number };

type RenderCellParams = {
  value: string;
  data: RenderCellData;
  cellProps?: CellProps;
  row: PivotTableRow;
};

type RenderGroupValueParams = {
  value: string;
  data: RenderCellData;
};

const MIN_HEADER_WIDTH = 100;
const GROUP_COLUMN_ROW_INDENT = 20;
export function generatePivotColumns({
  columnConfigs,
  schema,
  pivotColumns,
  groupByColumns,
  summaryColumnName,
  wrapHeaderText,
}: GeneratePivotColumnParams): PivotColumns {
  const pivotSet = new Set(pivotColumns || []);
  const groupBySet = new Set(groupByColumns);

  const schemaByName = schema ? keyBy(schema, (col) => col.name) : {};

  const columns: TypeColumn[] = (schema ?? []).map((columnInfo) => {
    const { name, friendly_name, type } = columnInfo;
    const config = columnConfigs[name];

    const groupSummaryReducer: TypeSummaryReducer | undefined =
      pivotSet.has(name) || groupBySet.has(name) ? undefined : firstReducer;

    return {
      name,
      header: friendly_name,
      defaultFlex: 1,
      minWidth: MIN_HEADER_WIDTH,
      textAlign: getCellAlignment(config?.displayFormatting, type),
      groupSummaryReducer,
      renderHeader: (cellProps) => (
        <ColumnHeader
          {...cellProps}
          hideMenu
          alignment={getFlexAlignments(config?.displayFormatting, type)}
          column={columnInfo}
          shouldTruncateText={!wrapHeaderText}
          sort={cellProps.computedSortInfo}
        />
      ),
      render: ({ data, value, cellProps }: RenderCellParams) => {
        const { leaf, array } = data;
        // Checking value == null handles an edge case where if you pivot and group by the same column, "row" below will be incorrect
        if (!leaf || value == null) return null;

        const row = array.find((row) => {
          // Match the cellProps.id format, which is groupByKey1:groupByValue1_groupByKey2:groupByValue2-aggKey
          const groupByStr = pivotColumns?.length
            ? pivotColumns.map((groupBy) => `${groupBy}:${row[groupBy]}`).join('_') + '-'
            : '';
          const id = groupByStr + name;
          return id === cellProps?.id;
        });

        if (cellProps) {
          const cellStyles = row?.[getCellStyleId(name)] as CellStyleOverrides | undefined;
          if (cellStyles) {
            cellProps.style = { ...cellProps.style, ...cellStyles };
            if (summaryColumnName && isCellPropIdSummaryCell(cellProps.id, summaryColumnName)) {
              // Undoes applying gradient styling to Summary columns
              cellProps.style.backgroundColor = undefined;
              cellProps.style.color = undefined;
            }
          }
        }

        // Render transformed value (using transformRows)
        return row?.[getCellTransformedId(name)] || value;
      },
    };
  });

  const headerNames: string[] = [];
  groupByColumns.forEach((colName) => {
    const schemaCol = schemaByName[colName];
    if (schemaCol) headerNames.push(schemaCol.friendly_name ?? schemaCol.name);
  });

  const headerName = headerNames.join(' / ');

  /**
   * If there is no text wrap we need the min width to scale up by number of group by columns
   * If there is text wrap and multiple groupByColumns we still need to scale up for the readability of the
   * actual rows since we don't have text wrap on them and they will have an indent for every grouping added
   */
  const minWidth = wrapHeaderText
    ? MIN_HEADER_WIDTH + GROUP_COLUMN_ROW_INDENT * (groupByColumns.length - 1)
    : groupByColumns.length * MIN_HEADER_WIDTH;

  const groupColumn: TypeColumn = {
    header: headerName,
    defaultFlex: 1,
    minWidth,
    className: styles.groupCell,
    renderHeader: (cellProps) => (
      <ColumnHeader
        {...cellProps}
        hideMenu
        alignment="flex-end"
        column={{ name: headerName, type: 'string' }}
        shouldTruncateText={!wrapHeaderText}
      />
    ),
    renderGroupValue: ({ data, value }: RenderGroupValueParams) => {
      if (value == null) return null;

      // Render transformed value (using transformRows)
      const { array } = data;

      // array should only contain 1 item corresponding to the row being rendered
      const row = array?.[0];

      // If there are 2 group bys, then when rendering the 2nd group by, depth=2 (they match 1:1)
      const renderedValue = row?.[getCellTransformedId(groupByColumns[data.depth - 1])] || value;
      return <div className={cellClassName}>{renderedValue}</div>;
    },
  };

  return { columns, groupColumn };
}

const cellClassName = sprinkles({ flex: 1, truncateText: 'ellipsis' });

export const renderValue = (
  value: string | number | undefined,
  column: DatasetColumn | undefined,
  config?: { displayFormatting?: DisplayOptions },
): string | number => {
  if (value === null || value === undefined || !column) return '';

  const { type } = column;
  const displayOptions = config?.displayFormatting;
  if (!displayOptions) {
    // defaultFormatCellData returns JSX for Booleans, we need to prevent that
    return type === BOOLEAN ? String(value) : String(defaultFormatCellData(value, column));
  }

  if (NUMBER_TYPES.has(type)) {
    return renderNumberCell({
      cellData: value,
      displayOptions: displayOptions as NumberDisplayOptions,
      isFloatCol: DECIMAL_TYPES.has(type),
    }) as string;
  } else if (DATE_TYPES.has(type)) {
    return formatDateField(
      value as string,
      type,
      displayOptions as DateDisplayOptions,
      false,
      true,
    );
  }

  return String(value);
};

// This reducer doesn't actually do anything since it should have nothing to reduce on
const firstReducer = { initialValue: 0, reducer: (_: number, b: number) => b };

/**
 * Should be used to transform/render data before passing it to ReactDataGrid to improve efficiency
 */
export const transformRows = (
  rows: DatasetRow[] | undefined,
  schema: DatasetSchema | undefined,
  columnConfigs: ColumnConfigs,
  pivotColumns: string[] | undefined,
): EmbedPivotTableRow[] => {
  if (!rows?.length || !schema?.length) return [];

  return rows.map((row) => {
    const newRow: EmbedPivotTableRow = {};
    schema.forEach((columnInfo) => {
      const name = columnInfo.name;
      const value = row[name];
      const config = columnConfigs[name];

      // Pivot values are rendered as column headers which don't have a custom render function so they need to be pre-rendered
      if (pivotColumns?.includes(name)) {
        newRow[name] = renderValue(value, columnInfo, config);
        return;
      }

      // Group by values and cell values need to be clickable so we need to have both the value and the rendered value
      newRow[name] = value;
      newRow[getCellTransformedId(name)] = renderCell({
        config: config,
        columnInfo,
        row,
        value,
      });
    });
    return newRow;
  });
};

type PivotTableRow = Record<string, string | number | CellStyleOverrides>;

const CELL_TRANSFORMED_KEY = '_transformed';
const getCellTransformedId = (name: string) => `${name}${CELL_TRANSFORMED_KEY}`;

const CELL_STYLE_KEY = '_cell_styles';
const getCellStyleId = (name: string) => `${name}${CELL_STYLE_KEY}`;

// No need to create a shared function with above for now since its simple
// But as more gets added might be good to share between the two
export const transformRowsWithCellStyles = (
  rows: DatasetRow[] | undefined,
  schema: DatasetSchema | undefined,
  columnConfigs: ColumnConfigs,
  pivotColumns: string[],
  includeRollup: boolean,
  summaryColumnName: string,
): PivotTableRow[] => {
  if (!rows?.length || !schema?.length) return [];

  const metricsByColumn = getColumnMetricsForGradient(rows, schema, columnConfigs);

  return rows.map((row) => {
    const newRow: PivotTableRow = {};
    schema.forEach((columnInfo) => {
      const name = columnInfo.name;
      const colConfig = columnConfigs[name];
      const value = row[name];
      newRow[name] = renderValue(value, columnInfo, colConfig);

      // This is the header for the Summary column
      if (!newRow[name] && includeRollup && name === pivotColumns[0]) {
        newRow[name] = summaryColumnName;
      }

      if (value != null) {
        const cellStyles = getCellStyles({
          cellData: value,
          colType: columnInfo.type,
          displayOptions: colConfig?.displayFormatting,
          metrics: metricsByColumn[name],
        });
        if (cellStyles) newRow[getCellStyleId(name)] = { ...cellStyles };
      }
    });
    return newRow;
  });
};

export const getFooterRow = (
  schema: DatasetSchema,
  columnConfigs: ColumnConfigs,
  showSummary: boolean,
  keyPathToSummary: Record<string, DatasetRow>,
  tableSummaryGroupName: string,
): TypeFooterRow[] => {
  return [
    {
      render: ({ column }) => {
        // When showSummary is false, render null rather than set footerRows=[] because
        // react-data-grid won't re-render correctly and will leave a blank space where the footer should be
        if (
          !showSummary ||
          !column.name ||
          !keyPathToSummary[TABLE_SUMMARY] ||
          column.group !== tableSummaryGroupName
        )
          return null;

        const value = keyPathToSummary[TABLE_SUMMARY][column.name];
        if (!value) return null;

        const columnInfo = schema.find((s) => s.name === column.name);
        if (!columnInfo) return null; // Should never happen since the column should always exist in the schema

        const config = columnConfigs[column.name];
        const flexAlignment = getFlexAlignments(config.displayFormatting, columnInfo.type);

        return (
          <div className={cx(footerCellClassName, sprinkles({ justifyContent: flexAlignment }))}>
            <b className={sprinkles({ truncateText: 'ellipsis' })}>
              {renderCell({
                config,
                value: typeof value === 'string' ? parseFloat(value) : value,
                columnInfo,
              })}
            </b>
          </div>
        );
      },
      cellStyle: { height: showSummary ? SUMMARY_FOOTER_HEIGHT : EMPTY_FOOTER_HEIGHT },
    },
  ];
};

const getColumnMetricsForGradient = (
  rows: DatasetRow[],
  schema: DatasetSchema,
  columnConfigs: ColumnConfigs,
): MetricsByColumn => {
  const metricsByColumn: MetricsByColumn = {};
  const schemaLength = schema.length;
  schema.forEach(({ name }) => {
    const colConfig = columnConfigs[name];
    if (!colConfig?.displayFormatting) return;
    const displayOptions = colConfig.displayFormatting as NumberDisplayOptions;
    if (!isDataRequiredForTableColumnGradient(displayOptions)) return;

    const columnMetics = calculateColumnMetrics(rows, name, schemaLength);
    if (columnMetics) {
      metricsByColumn[name] = columnMetics;
    }
  });
  return metricsByColumn;
};

export const calculateColumnMetrics = (
  rows: DatasetRow[],
  columnName: string,
  schemaLength: number,
): { min: number; max: number; avg: number } | null => {
  let min = Infinity;
  let max = -Infinity;
  let total = 0;
  let nonSummaryRowCount = 0;
  rows.forEach((row) => {
    if (Object.keys(row).length === schemaLength) {
      const rowValue = row[columnName];
      const value = typeof rowValue === 'string' ? parseFloat(rowValue) : rowValue;
      if (value != null) {
        nonSummaryRowCount += 1;
        min = Math.min(min, value);
        max = Math.max(max, value);
        total += value;
      }
    }
  });
  if (nonSummaryRowCount > 0) {
    return { min, max, avg: total / nonSummaryRowCount };
  } else {
    return null;
  }
};

export const TABLE_SUMMARY = 'tableSummary';
export const PIVOT_SUMMARY_COLUMN_HEADER = 'Summary';

const footerCellClassName = sprinkles({ display: 'flex', width: 'fill' });

export const isCellPropIdSummaryCell = (
  id: string | number | undefined,
  summaryColumnName: string,
) => {
  return !!id?.toString()?.includes(`:${summaryColumnName}-`);
};

const PIVOT_TABLE_HEADER_HEIGHT = 42;
export const SUMMARY_FOOTER_HEIGHT = 32;
export const EMPTY_FOOTER_HEIGHT = 16;

export const getPivotTableHeaderHeight = (numPivots: number | undefined) =>
  ((numPivots || 0) + 1) * PIVOT_TABLE_HEADER_HEIGHT;

const isMissingGroupBy = (row: DatasetRow, groupBys: string[]) =>
  groupBys.some((col) => row[col] === undefined);

/**
 * Requires that dataset rows are ordered such dataset rows are returned grouped with their respective table rows.
 *
 * Will iterate over ordered rows (skipping possible table summary or row group summary values marked by a missing group by).
 * Keep track of all the current row configurations to know if we are looking at the same table row.
 * If only last row instruction changes then we know it's a simple new row.
 * If any other row instruction changes, it means it's the start of a new row group.
 *
 * Example:
 * Table Summary (skip)
 * Sunday Group Summary (skip - since accounted for later)
 * Sun, Jan (init with 2 rows by how many row instructions - one for group Sun, one for Jan)
 * Sun, Feb (last row instruction changed, simple counter increment)
 * Mon, Jan (non last row instruction changed, counter incremented twice for new row + row group)
 * Mon, Feb (last row instruction changed, simple counter increment)
 *
 */
export const getPivotTableRowCount = (
  groupBys: string[],
  datasetRows: DatasetRow[] | undefined,
) => {
  if (!datasetRows?.length) return 0;

  const currentTableRowTempIndex = datasetRows.findIndex((row) => !isMissingGroupBy(row, groupBys));
  if (currentTableRowTempIndex === -1) return 0;

  // table row count starts at group bys length to account for nested rows
  let tableRowCount = groupBys.length;
  let currentTableRowTemp = datasetRows[currentTableRowTempIndex];

  for (let i = currentTableRowTempIndex + 1; i < datasetRows.length; i++) {
    const currentRow = datasetRows[i];

    if (isMissingGroupBy(currentRow, groupBys)) continue;

    const rowInstructionChangedIndex = groupBys.findIndex(
      (col) => currentRow[col] !== currentTableRowTemp[col],
    );
    if (rowInstructionChangedIndex > -1) {
      currentTableRowTemp = currentRow;
      // Increment depends on which instruction is changed first
      // i.e. last instruction simple + 1,
      // second to last instruction implies row + grouped row, and so on
      tableRowCount += groupBys.length - rowInstructionChangedIndex;
    }
  }

  return tableRowCount;
};
