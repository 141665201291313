import { FC, useMemo } from 'react';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { ExplorePivotTable } from 'components/embed/EmbedPivotTable/ExplorePivotTable';
import * as styles from 'components/embed/EmbedPivotTable/index.css';
import { PIVOT_ROW_LIMIT_REACHED_CONTAINER_HEIGHT } from 'components/embed/EmbedPivotTable/index.css';
import {
  ensureProperPivotColumnSorting,
  extractSummaryRows,
} from 'components/embed/EmbedPivotTable/pivotTableDataProvider';
import {
  EMPTY_FOOTER_HEIGHT,
  PIVOT_SUMMARY_COLUMN_HEADER,
  SUMMARY_FOOTER_HEIGHT,
  getPivotTableHeaderHeight,
  getPivotTableRowCount,
} from 'components/embed/EmbedPivotTable/pivotUtils';
import { SortOrder } from 'constants/types';
import { GRID_ROW_HEIGHT } from 'pages/ReportBuilder/constants';
import { PIVOT_ROW_LIMIT } from 'reportBuilderContent/thunks/utils';
import { DashboardVariableMap, TEXT_ELEM_HORIZ_ALIGNMENTS } from 'types/dashboardTypes';
import { DataPanelData } from 'types/dataPanelTemplate';
import { getPivotSchemaAndConfigs } from 'utils/V2ColUtils';
import { isPivotTableV2ReadyToDisplay } from 'utils/dataPanelConfigUtils';
import { canPivotTableSummarize } from 'utils/fido/fidoShims';
import { keyBy } from 'utils/standard';

import { NeedsConfigurationPanel } from '../../needsConfigurationPanel';

type Props = {
  instructions: V2PivotTableInstructions | undefined;
  data: DataPanelData | undefined;
  isScreenshotDownload?: boolean;
  variables: DashboardVariableMap;
};

export const PivotTableV2: FC<Props> = ({
  instructions,
  data,
  isScreenshotDownload,
  variables,
}) => {
  const { schema, columnConfigs, pivotColumns, groupByColumns } = useMemo(
    () =>
      getPivotSchemaAndConfigs(
        instructions?.columnConfigs || {},
        instructions?.rowGroupBys || [],
        instructions?.colGroupBys || [],
        instructions?.aggregations || [],
        undefined,
        variables,
        true,
      ),
    [
      instructions?.aggregations,
      instructions?.rowGroupBys,
      instructions?.colGroupBys,
      instructions?.columnConfigs,
      variables,
    ],
  );

  const nameSchemaMap = useMemo(() => keyBy(schema || [], 'name'), [schema]);

  const isRowLimitReached = useMemo(
    () => (data?.rows || [])?.length >= PIVOT_ROW_LIMIT,
    [data?.rows],
  );

  const { keyPathToSummary, rows } = useMemo(() => {
    const hasRowInstructions = groupByColumns?.length;
    const hasColumnInstructions = !!instructions?.colGroupBys?.length && !!pivotColumns?.length;
    const hasAggInstructions = !!instructions?.aggregations?.length;
    if (
      !data?.rows?.length ||
      !hasRowInstructions ||
      !hasColumnInstructions ||
      !hasAggInstructions
    ) {
      return { keyPathToSummary: {}, rows: data?.rows };
    }

    const { keyPathToSummary, rows } = instructions?.includeRollup
      ? extractSummaryRows(data.rows, nameSchemaMap, groupByColumns, columnConfigs)
      : { keyPathToSummary: {}, rows: data.rows };

    const needsToSortPivotColumns = hasColumnInstructions && hasAggInstructions;

    const sortOrder =
      instructions.columnSortOrder === SortOrder.DESC ? SortOrder.DESC : SortOrder.ASC;
    return {
      keyPathToSummary,
      rows: needsToSortPivotColumns
        ? ensureProperPivotColumnSorting(
            rows,
            pivotColumns[0],
            instructions.colGroupBys[0],
            nameSchemaMap,
            groupByColumns,
            sortOrder,
          )
        : rows,
    };
  }, [
    data?.rows,
    nameSchemaMap,
    groupByColumns,
    columnConfigs,
    pivotColumns,
    instructions?.includeRollup,
    instructions?.colGroupBys,
    instructions?.aggregations?.length,
    instructions?.columnSortOrder,
  ]);

  const showSummary = useMemo(
    () =>
      !!instructions?.includeRollup &&
      canPivotTableSummarize({
        aggregations: instructions.aggregations,
        rowGroupBys: instructions.rowGroupBys,
        colGroupBys: instructions.colGroupBys,
      }),
    [
      instructions?.includeRollup,
      instructions?.aggregations,
      instructions?.rowGroupBys,
      instructions?.colGroupBys,
    ],
  );

  // For PDF exports we need to compute the height of the react data grid to export all the pages
  const height = useMemo(() => {
    if (!isScreenshotDownload || !data?.rows?.length) {
      // data rows length will never be undefined or 0, otherwise rendering the no data panel
      return '100vh';
    }

    const headerHeight = getPivotTableHeaderHeight(pivotColumns?.length);
    const footerHeight = showSummary ? SUMMARY_FOOTER_HEIGHT : EMPTY_FOOTER_HEIGHT;
    const pivotRowLimitContainerHeight = isRowLimitReached
      ? PIVOT_ROW_LIMIT_REACHED_CONTAINER_HEIGHT
      : 0;

    const numRows = getPivotTableRowCount(groupByColumns || [], data.rows);

    return numRows * GRID_ROW_HEIGHT + headerHeight + footerHeight + pivotRowLimitContainerHeight;
  }, [
    showSummary,
    data?.rows,
    pivotColumns,
    groupByColumns,
    isScreenshotDownload,
    isRowLimitReached,
  ]);

  const pivotColumnAlignment = useMemo(() => {
    if (!pivotColumns?.length) return styles.explorePivotTableGroupHeaderRight;
    switch (columnConfigs[nameSchemaMap[pivotColumns[0]].name].displayFormatting?.alignment) {
      case TEXT_ELEM_HORIZ_ALIGNMENTS.CENTER:
        return styles.explorePivotTableGroupHeaderCenter;
      case TEXT_ELEM_HORIZ_ALIGNMENTS.LEFT:
        return styles.explorePivotTableGroupHeaderLeft;
      default:
        return styles.explorePivotTableGroupHeaderRight;
    }
  }, [pivotColumns, columnConfigs, nameSchemaMap]);

  if (!isPivotTableV2ReadyToDisplay(instructions)) {
    return <NeedsConfigurationPanel instructionsNeedConfiguration />;
  }

  return (
    <div style={{ height }}>
      <ExplorePivotTable
        columnConfigs={columnConfigs}
        groupByColumns={groupByColumns ?? []}
        isRowLimitReached={isRowLimitReached}
        keyPathToSummary={keyPathToSummary}
        loading={data?.loading ?? true}
        pivotColumnAlignment={pivotColumnAlignment}
        pivotColumns={pivotColumns ?? []}
        rows={rows}
        schema={schema}
        showSummary={showSummary}
        summaryColumnName={instructions?.summaryColumnName || PIVOT_SUMMARY_COLUMN_HEADER}
        wrapHeaderText={instructions?.wrapHeaderText}
      />
    </div>
  );
};
